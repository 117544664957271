import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import store from './store'
import MetaInfo from 'vue-meta-info'
import less from 'less'
import http from './api/http.js'
import qs from 'qs'
import router from './router'
import axios from 'axios'
import VueAnimateNumber from 'vue-animate-number'
import moment from "moment";
const VueScrollTo = require('vue-scrollto')
Vue.use(VueAnimateNumber)
Vue.use(less)
Vue.use(MetaInfo)
Vue.use(VueScrollTo)
Vue.config.productionTip = false
Vue.prototype.axios = axios;
Vue.prototype.http = http;
Vue.prototype.qs = qs;
Vue.use(VueScrollTo, {
	container: 'body', // 滚动元素
	duration: 500, // 动画时长
	easing: 'ease', // 动画曲线
	offset: 0, // 滚动终点距离父元素顶部距离
	force: true, // 强制立即执行，即便元素是可见的
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false, // x 轴禁止滚动
	y: true
})
const mixin = {
	filters: {
		keepOne(val) {
			return Number(val.toString().match(/^\d+(?:\.\d{0,1})?/));
		},
		handlePay(aa, bb) {
			var textSalary = ''
			if ((aa == '' && bb == '') || (aa == 0 && bb == 0)) {
				textSalary = '面议'
			} else if (aa != '' && bb == '') {
				textSalary = aa + '以上'
			} else if (aa == '' && bb != '') {
				textSalary = bb + '以下'
			} else if (aa != '' && bb != '') {
				textSalary = aa + '-' + bb
			}
			return textSalary
		}
	},
	methods: {
		Dateformat(cellValue, type) {
			//日期格式化
			return cellValue ? moment(cellValue).format(type) : '--';
		},
		filterReturn(arr, type) {
			//枚举过滤
			return type ? arr.filter((item) => {
				return item.value == type;
			})[0].label : '--';
		},
		setCacheData(name, val) {
			// 设置缓存
			localStorage.setItem(name, val);
		},
		getCacheData(name) {
			// 获取缓存
			return localStorage.getItem(name);
		},
		removeCacheData(name) {
			// 清除缓存
			localStorage.removeItem(name);
		},
		/**
		 * 返回顶部
		 */
		topFunction() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		},
		handelHttps(str) {
			if (!str) {
				return ''
			}
			if (str.startsWith("https://")) {
				return str
			} else if (str.startsWith("http://")) {
				return str.replace("http://", "https://")
			} else {
				return 'https://' + str
			}
		}
	}
}
Vue.mixin(mixin)

new Vue({
	store,
	router,
	render: h => h(App),
	mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')