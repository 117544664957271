<template>
  <div id="app">
    <router-view></router-view>
    <template v-if="positionOn">
      <tail></tail>
      <broadside></broadside>
    </template>
  </div>
</template>

<script>
import tail from "./components/tail.vue";
import broadside from "./components/broadside.vue";
export default {
  name: "app",
  components: {
    tail,
    broadside,
  },
  computed: {
    positionOn() {
      return this.$route.path !== "/login" && this.$route.path !== "/manager";
    },
  },
  mounted() {
    // process.on('unhandledRejection', error => {
    // 	console.error('unhandledRejection', error);
    // 	process.exit(1) // To exit with a 'failure' code
    // });
  },
};
</script>

<style lang="less">
//表格样式
.HNMR {
  &.noHead {
    &:before {
      height: 0;
    }
    thead {
      display: none;
    }
  }

  thead {
    th {
      height: 56px;
      padding: 0;
      background: #f3f3f3 !important;
      font-weight: 400;
      font-size: 14px;
      font-family: PingFang SC;
      color: #333333;
      border-bottom: none;
    }
  }
  .nodata {
    img {
      width: 100px;
      height: 100px;
      margin: 20px auto 0;
    }
    text-align: center;
    margin-bottom: 20px;
  }
  margin-top: 10px;
}
.el-pagination {
  float: right;
  font-weight: 500 !important;
  margin: 60px auto 0;
}

// .el-pager .active {
//   background-color: #00bcd4 !important;
// }

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FadeInTwo {
  animation: fadeInUpTwo 1000ms both;
}
.FadeNone {
  opacity: 0;
}

/* 由下而上淡入 */
.FadeIn {
  animation: fadeInUp 1000ms both;
}

/* 从左侧淡入 */
.fadeInLeft {
  animation: fadeInLeft 1000ms both;
}

.slideIn {
  animation: slideInRight 1000ms both;
}

/* 由底部旋转淡入 */
.rotateIn {
  animation: rotateInLeft 1000ms both;
}

/* 放大10倍淡入 */
.puffBigIn {
  animation: puffBigIn 1500ms both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes puffBigIn {
  0% {
    transform: scale3d(10, 10, 10);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInLeft {
  from {
    transform-origin: center 80%;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    transform-origin: center 80%;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes floatY {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpTwo {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jump {
  from,
  10% {
    transform: translateY(0) scale3d(1, 1, 1);
  }

  30% {
    transform: translateY(10%) scale3d(1.05, 0.8, 1);
  }

  50% {
    transform: translateY(-50%) scale3d(1, 1.1, 1);
  }

  65% {
    transform: translateY(0) scale3d(1, 1, 1);
  }

  75% {
    transform: translateY(8%) scale3d(1.08, 0.84, 1);
  }

  85% {
    transform: translateY(6%) scale3d(1.05, 0.88, 1);
  }

  90%,
  to {
    transform: none;
  }
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #00bcd4 !important;
}

.el-select-dropdown .selected {
  color: #00bcd4 !important;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  -webkit-text-size-adjust: 100%;
}

a {
  color: #2d374b;
  text-decoration: none;
}

a:hover {
  color: #00bcd4;
  text-decoration: underline;
}

em {
  font-style: normal;
}

li {
  list-style: none;
}

img {
  border: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

p {
  word-wrap: break-word;
}
/* // 设置滚动条整体样式 */
::-webkit-scrollbar {
  width: 10px;
}

/* / 滚动条方块样式 */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  cursor: pointer;
}

/* // 滚动条轨道样式 */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
}
/* 特殊按钮样式 */
.dazzle-button {
  width: 123px;
  height: 36px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
  position: relative;
  .button-text {
    font-size: 16px;
    color: white;
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 36px;
  }
}
.dazzle-button::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #0bbafb 0%, #4285ec 100%);
  border-radius: 4px;
  z-index: 10;
}
.dazzle-button::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 100%;
  height: 100%;
  border: 1px solid #3393f0;
  border-radius: 4px;
  z-index: 5;
}
.el-dialog{
  margin-top: 25% !important;
  transform: translate(0,-50%);
}
</style>
