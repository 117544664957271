<template>
  <div class="content">
    <div class="top">
      <div class="Hotline">
        <img src="../images/home/p1.png" />
        <div>联系我们</div>
        <p>400-0550-766</p>
      </div>
      <div class="friendChain">
        <div v-for="(item, index) in friendChain" :key="index" class="list">
          <p>{{ item.name }}</p>
          <span
            v-for="(item2, index2) in item.list"
            :key="index2"
            @click="toPage(item2.url)"
            >{{ item2.name }}</span
          >
        </div>
      </div>
      <div class="entrance">
        <!-- <div class="neirong">
					<img src="https://szj-asset.oss-cn-shenzhen.aliyuncs.com/official/icon/P7.png" >
					<p>上职家微信小程序</p>
				</div> -->
        <div class="neirong">
          <img src="../images/home/code.png" />
          <p>海恒科技集团公众号</p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="text">
        Copyright © 2010-2023 海恒科技集团 版权所有
        <img
          src="https://szj-asset.oss-cn-shenzhen.aliyuncs.com/official/icon/P3.png"
        />
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
          >湘ICP备20000050号-3</a
        >

        <!-- 湘公网安备 XXXXXXXXX号 -->
      </div>
      <!-- <div class="icon">
				<div></div>
				<div></div>
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "tail",
  data() {
    return {
      friendChain: [
        {
          name: "解决方案",
          list: [
            {
              name: "灵活用工",
              url: "/programme/laborServices",
            },
            {
              name: "人才配置",
              url: "/programme/talent",
            },
            {
              name: "数字员工",
              url: "/programme/technology",
            },
          ],
        },
        {
          name: "企业案例",
          list: [
            {
              name: "岗位外包",
              url: "/case?show=0",
            },
            {
              name: "业务外包",
              url: "/case?show=1",
            },
            {
              name: "劳务派遣",
              url: "/case?show=2",
            },
            {
              name: "招聘流程外包",
              url: "/case?show=3",
            },
          ],
        },
        {
          name: "海恒动态",
          list: [
            {
              name: "公司新闻",
              url: "/trends?show=0",
            },
            {
              name: "媒体报道",
              url: "/trends?show=1",
            },
            // {
            //   name: "行业资讯",
            //   url: "/trends?show=2",
            // },
          ],
        },
        {
          name: "关于海恒",
          list: [
            {
              name: "集团概况",
              url: "/about?show=0",
            },
            {
              name: "社会责任",
              url: "/about?show=1",
            },
            {
              name: "联系我们",
              url: "/about?show=2",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {
    toPage(url) {
      this.topFunction();
      url ? this.$router.push(url) : "";
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .bottom {
    .icon {
      div {
        width: 110px;
        height: 39px;
        background-color: #84929f;
        border-radius: 4px;
        margin-right: 20px;
      }
      margin: 18px auto 0;
      display: flex;
      width: 240px;
    }
    .text {
      a {
        color: #c3c3c3;
      }
      img {
        margin-top: -4px;
      }
      text-align: center;
      font-size: 14px;
      color: #84929f;
    }
    padding: 29px 0 0;
  }
  .top {
    .entrance {
      .neirong {
        margin-right: 40px;
      }
      img {
        width: 100px;
        height: 100px;
        background-color: #ffffff;
        margin-bottom: 13px;
        border-radius: 4px;
      }
      color: #84929f;
      font-size: 12px;
      display: flex;
    }
    .friendChain {
      .list {
        a,
        span {
          font-size: 14px;
          color: #84929f;
          margin-bottom: 10px;
          cursor: pointer;
          display: block;
        }
        a:hover {
          color: #00bcd4;
        }
        p {
          font-size: 16px;
          color: #ffffff;
          margin-bottom: 20px;
        }
      }
      width: 542px;
      margin: 0 180px 0 160px;
      display: flex;
      justify-content: space-between;
    }
    .Hotline {
      width: 155px;
      p {
        font-size: 20px;
        font-weight: bold;
        margin-top: 5px;
      }
      img {
        width: 34px;
        height: 29px;
        margin-bottom: 15px;
      }
      font-size: 16px;
      color: #ffffff;
    }
    width: 1200px;
    display: flex;
    margin: auto;
    padding-bottom: 40px;
    border-bottom: 1px solid #05233e;
  }
  background-color: #021a30;
  padding: 59px 0 30px;
}
</style>
