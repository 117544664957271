<!-- 模块说明 -->
<template>
  <div class="home">
    <subHead :active="0"></subHead>
    <div class="banner">
      <el-carousel
        trigger="click"
        height="680px"
        @change="carousel"
        :interval="5000"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <template v-if="item.type == 'image'">
            <img
              :class="{ textOn: bannerOn == index }"
              class="text"
              :src="item.imgText"
            />
            <img class="back" :src="item.imgBack" />
          </template>
          <template v-if="item.type == 'video'">
            <img
              @click="videoPlayerTo(index)"
              class="back"
              :src="item.imgBack"
            />
          </template>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="modular modular-1">
      <div class="title">
        <div class="title-ico">NEWS</div>
        <div class="title-name">
          海恒动态
          <img @click="jumpTo('/trends?show=0')" src="../images/home/p4.png" />
        </div>
      </div>
      <div
        @mouseover="changeActive()"
        @mouseout="removeActive()"
        class="bpttom"
      >
        <div class="content">
          <div class="right">
            <el-image
              :class="{ on: hotspotOn == index2 }"
              v-for="(item, index2) in hotspotList"
              :key="index2"
              :src="handelHttps(item.articleThumbnailUrl)"
              fit="cover"
            ></el-image>
            <div class="indicator flex">
              <div
                @click="hotspotOn = index"
                :class="{ on: hotspotOn == index }"
                v-for="(item, index) in hotspotNum"
                :key="index"
              ></div>
            </div>
          </div>
          <div class="left">
            <template v-for="(item, index) in hotspotList">
              <div v-if="hotspotOn == index" :key="index" class="list">
                <h4 class="FadeIn">{{ item.title }}</h4>
                <p class="FadeIn">{{ item.abstractOfArticle }}</p>
                <div
                  @click="
                    jumpTo(
                      `/details?hotspotList=${encodeURIComponent(
                        JSON.stringify(hotspotList)
                      )}&index=${index}`
                    )
                  "
                  class="more FadeIn"
                >
                  MORE
                </div>
                <!-- <a
                  :target="item.url ? '_blank' : '_self'"
                  :href="item.url ? item.url : 'javascript:;'"
                ></a> -->
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="modular modular-2">
      <img id="id1" src="../images/home/p8.png" alt="" />
    </div>
    <div class="modular modular-3">
      <div class="title">
        <div class="t1">解决方案</div>
        <div class="t2">人力资源综合解决方案</div>
        <div class="t3"></div>
      </div>
      <div class="content flex">
        <div
          v-for="(item, index) in solveList"
          :key="index"
          class="view"
          :class="'view' + index"
        >
          <img :src="item.img" />
          <div class="masking"></div>
          <div class="show">
            <div class="t1">{{ item.name }}</div>
            <div class="t2">{{ item.text }}</div>
            <div class="t3" @click="jumpTo(item.url)">MORE</div>
            <div class="bt"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modular modular-4">
      <div id="id2" class="content flex">
        <div v-for="(item, index) in numList" :key="index">
          <div class="top">
            <div class="num">
              <animate-number
                v-if="animateNumber"
                from="0"
                duration="2000"
                :to="item.data"
              ></animate-number>
              {{ index == 2 ? "万" : "" }}
            </div>
            <div class="unit"><span>+</span>{{ item.unit }}</div>
          </div>
          <div class="bottom">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="modular modular-5">
      <div class="title">
        <div class="t1">不断拓展客户边界</div>
        <div class="t2">
          成功服务了<span>500+</span>知名企业/机构，促进企业高质量发展
        </div>
      </div>
      <img id="id3" src="../images/home/p7.png" />
    </div>
    <el-dialog
      title="视频播放"
      :visible.sync="centerDialogVisible"
      :before-close="videoPause"
      width="50%"
      center
    >
      <videoPlayer
        :options="playerOptions"
        ref="videoPlayer"
        class="video-player"
      ></videoPlayer>
    </el-dialog>
  </div>
</template>

<script>
import subHead from "../components/subHead.vue";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
export default {
  name: "Home",
  components: { subHead, videoPlayer },
  data() {
    return {
      bannerList: [
        {
          type: "image",
          imgText: require("../images/home/p12.png"),
          imgBack:
            "https://szj-asset.oss-cn-shenzhen.aliyuncs.com/mp/HHKJ/p14.png",
        },
        // {
        //   type: "video",
        //   mv: "https://szj-asset.oss-cn-shenzhen.aliyuncs.com/official/icon/MV.mp4",
        //   imgBack: require("../assets/banner/banner2.png"),
        // },
      ],
      bannerOn: 0,
      playerOptions: {
        autoplay: true,
        preload: "auto",
        fluid: true,
        aspectRatio: "16:9",
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        language: "zh-CN",
        sources: [
          {
            type: "video/mp4", // 类型
            src: "", // url地址
          },
        ],
        loop: true,
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
      },
      centerDialogVisible: false,
      hotspotList: [],
      hotspotNum: 3,
      hotspotOn: 0,
      styleList: [1, 2, 3],
      solveList: [
        {
          name: "劳务服务",
          text: "灵活用工解决方案",
          img: require("../images/home/za/p3.png"),
          id: 0,
          url: "/programme/laborServices?show=0",
        },
        {
          name: "人才服务",
          text: "人才配置解决方案",
          img: require("../images/home/za/p5.png"),
          id: 1,
          url: "/programme/talent?show=1",
        },
        {
          name: "技术服务",
          text: "数字员工解决方案",
          img: require("../images/home/za/p2.png"),
          id: 2,
          url: "/programme/technology?show=2",
        },
      ],
      numList: [
        {
          data: 20,
          text: "全国布局",
          unit: "分子公司",
        },
        {
          data: 500,
          text: "服务团队",
          unit: "人员",
        },
        {
          data: 100,
          text: "人才资源",
          unit: "人才",
        },
      ],
      animateNumber: false,
    };
  },
  watch: {},
  created() {
    window.addEventListener("scroll", this.scrollToTop);
    this.getArticleListPage();
  },
  methods: {
    //获取文章列表
    getArticleListPage() {
      let params = {
        pageNum: 1,
        pageSize: 3,
        releaseStatus: "HAVE_RELEASED",
        type: "COMPANY_NEWS",
      };
      this.loading = true;
      this.http
        .get("/article/getArticleListPage", params)
        .then((res) => {
          this.hotspotList = res.data.data;
          this.timer = setInterval(() => {
            this.BannerSwitch();
          }, 5000);
          // this.totalNum = res.data.totalRecords;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    jumpTo(url) {
      this.topFunction();
      this.$router.push(url);
    },
    /**
     * 打开视频
     */
    videoPlayerTo(i) {
      this.playerOptions.sources[0].src = this.bannerList[i].mv;
      this.centerDialogVisible = true;
      // this.$refs.videoPlayer.player.pause();
    },
    //暂停视频播放
    videoPause() {
      this.$refs.videoPlayer.player.pause();
      this.centerDialogVisible = false;
    },
    carousel(i) {
      // console.log(i, '轮播图索引');
      this.bannerOn = i;
    },
    BannerSwitch() {
      if (this.hotspotOn < this.hotspotNum - 1) {
        this.hotspotOn++;
      } else {
        this.hotspotOn = 0;
      }
    },
    /**
     * 鼠标滑出
     */
    removeActive() {
      this.timer = setInterval(() => {
        this.BannerSwitch();
      }, 5000);
    },
    /**
     * 鼠标滑入
     */
    changeActive() {
      clearInterval(this.timer);
    },
    scrollToTop() {
      // 获取视窗高度
      var domHight = document.documentElement.clientHeight;
      // dom滚动位置
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 获取监听元素
      var id;
      // 获取监听元素本身高度
      var scrollHeight;
      // 获取监听元素距离视窗顶部距离
      var offsetTop;
      // 获取监听元素距离顶部高度-窗口高度
      var top;
      // 元素距离底部的高度+元素本身高度
      var bottom;
      if (this.styleList.length > 0) {
        this.styleList.map((i) => {
          id = document.getElementById(`id${i}`);
          scrollHeight = id.scrollHeight;
          offsetTop = id.offsetTop;
          top = offsetTop - domHight > 0 ? offsetTop - domHight : 0;
          bottom = scrollHeight + offsetTop;
          // 页面滚动位置 > 元素距离顶部高度-窗口高度 && 页面滚动位置 < 元素距离顶部高度+元素本身高度
          // console.log( scrollHeight, offsetTop, domHight, scrollTop, top, bottom );
          let showHeight = scrollTop - 200;
          if (showHeight >= top && showHeight <= bottom) {
            // console.log("元素出现在可视区: " + i);
            switch (i) {
              case 1:
                id.classList.add("FadeIn");
                break;
              case 2:
                this.animateNumber = true;
                break;
              case 3:
                id.classList.add("puffBigIn");
                break;
              // default:
              //   id.classList.add("FadeIn");
              //   break;
            }
          } else {
            // console.log("元素不在了: " + i);
            // id.classList.add("FadeNone");
            // switch (i) {
            //   case 1:
            //     id.classList.remove("rotateIn");
            //     break;
            //   case 3:
            //     id.classList.remove("puffBigIn");
            //     break;
            //   default:
            //     id.classList.remove("FadeIn");
            //     break;
            // }
          }
        });
      }
    },
  },
  beforeDestroy() {
    console.log("销毁");
    window.removeEventListener("scroll", this.scrollToTop);
    clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.modular-5 {
  img {
    width: 892px;
    height: 717px;
    display: block;
    margin: auto;
    opacity: 0;
  }
  .title {
    .t2 {
      span {
        color: #05447c;
      }
      font-size: 20px;
      color: #666666;
      margin: 15px 0 20px;
    }
    .t1 {
      font-size: 30px;
      color: #333333;
      font-weight: bold;
    }
    text-align: center;
    margin-bottom: 54px;
  }
  padding: 60px 0 40px;
  background-color: #f3f3f3;
}

.modular-4 {
  .content {
    .bottom {
      font-size: 24px;
      font-weight: 200;
    }
    .top {
      .unit {
        font-size: 18px;
        span {
          font-weight: bold;
          font-size: 24px;
        }
        margin-left: 10px;
        margin-top: 14px;
      }
      .num {
        font-size: 72px;
        font-weight: bold;
      }
      display: flex;
    }
    color: white;
    padding: 0 40px;
    margin-top: 30px;
  }
  height: 200px;
  background: url("../images/home/p11.png") no-repeat;
  background-size: cover;
  // background-attachment:fixed;
}
.modular-3 {
  .view:hover {
    .masking {
      animation: fadeIn 1000ms both;
    }
    .show {
      animation: fadeTop 500ms both;
      .bt {
        display: block;
      }
    }
  }
  @keyframes fadeIn {
    from {
      background-color: rgba(0, 0, 0, 0.5);
    }

    to {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  @keyframes fadeTop {
    from {
      top: 274px;
    }

    to {
      top: 200px;
    }
  }
  .view {
    .show {
      .bt {
        width: 30px;
        height: 1px;
        background-color: #ffffff;
        margin: 50px auto;
        display: none;
      }
      .t3:hover {
        background-color: white;
        color: rgba(0, 0, 0, 0.7);
      }
      .t3 {
        width: 66px;
        height: 28px;
        font-size: 14px;
        border: 1px solid #ffffff;
        border-radius: 4px;
        cursor: pointer;
        line-height: 28px;
        margin: auto;
      }
      .t2 {
        font-size: 18px;
        margin: 24px 0 49px;
      }
      .t1 {
        font-size: 24px;
        font-weight: bold;
      }
      div {
        text-align: center;
        color: #ffffff;
      }
      position: absolute;
      top: 274px;
      width: 100%;
      z-index: 3;
    }
    .masking {
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
    }
    img,
    .masking {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
    width: 350px;
    height: 490px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }
  .title {
    .t3 {
      width: 30px;
      height: 4px;
      background: #cae6ff;
      margin: auto;
    }
    .t2 {
      font-size: 20px;
      color: #666666;
      margin: 15px 0 20px;
    }
    .t1 {
      font-size: 30px;
      color: #333333;
      font-weight: bold;
    }
    text-align: center;
    margin-bottom: 40px;
  }
  padding: 49px 0 80px;
  background-color: #ffffff;
}
.modular-2 {
  img {
    width: 1159px;
    height: 562px;
    margin: 110px auto;
    display: block;
    opacity: 0;
  }
  height: 700px;
  background-color: #e3f1fe;
}
.modular-1 {
  .bpttom {
    .content {
      .right {
        .indicator {
          .on {
            background-color: #ffffff !important;
          }

          div:hover {
            background-color: #ffffff !important;
          }

          div {
            width: 9px;
            height: 9px;
            background-color: rgba(255, 255, 255, 0.5);
            margin-right: 10px;
          }

          width: 114px;
          height: 9px;
          cursor: pointer;
          justify-content: flex-start !important;
          position: absolute;
          bottom: 20px;
          left: 20px;
          z-index: 10;
        }
        position: relative;
        margin-right: 48px;
        top: -145px;
        background-color: #272727;
        width: 520px;
        height: 430px;
        border-radius: 5px;
        overflow: hidden;
        .on {
          animation: show 3500ms both;
        }

        .el-image {
          width: 520px;
          height: 430px;
          position: absolute;
          opacity: 0;
        }

        @keyframes show {
          to {
            opacity: 1;
          }

          from {
            opacity: 0;
          }
        }
      }

      .left {
        .more {
          display: inline-block;
          font-size: 14px;
          color: #999999;
          border: 1px solid #dddddd;
          border-radius: 4px;
          padding: 5px 15px;
          cursor: pointer;
          margin-top: 20px;
        }

        p {
          font-size: 14px;
          color: #666666;
          margin-bottom: 15px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 25px;
        }

        h4 {
          font-size: 20px;
          color: #0e0e0e;
          margin-bottom: 20px;
          margin-top: 50px;
          font-weight: 600;
          line-height: 30px;
        }

        width: 532px;
      }

      display: flex;
    }

    height: 330px;
    background-color: #f8f8f8;
    margin-top: 42px;
  }

  .title {
    .title-name {
      img {
        width: 28px;
        height: 28px;
        margin-left: 20px;
        margin-top: 3px;
        cursor: pointer;
      }
      display: flex;
      align-items: center;
      font-size: 30px;
      color: #333333;
      font-weight: bold;
      margin-top: 15px;
    }
    .title-ico {
      font-size: 50px;
      color: #ebf4fc;
    }
    margin-left: 912px;
  }
  padding-top: 60px;
}
.modular {
  width: 100%;
  overflow: hidden;
  background-position: center center;
}
.banner {
  .textOn {
    animation: fadeInUp 1800ms both;
  }

  .text {
    width: 1012px;
    height: 217px;
    position: absolute;
    top: 30%;
    left: 25%;
    transform: translate(-50%, -50%);
  }

  .back {
    width: 100%;
    height: 100%;
  }

  width: 100%;
  height: 680px;
  background-color: #52a0d0;
}
/deep/.vjs-big-play-button {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.content {
  width: 1200px;
  margin: auto;
}
.home {
  overflow: hidden;
  width: 100%;
}
</style>
