<!-- 模块说明 -->
<template>
  <div class="popon">
    <div class="ability">
      <!-- <div class="view">
        <img src="../images/home/p13.png" alt="" />
        <span>在线咨询</span>
      </div> -->
      <div class="view zhong">
        <img src="../images/home/p9.png" alt="" />
        <span>联系电话</span>
        <div class="fw fw-phone">
          <div class="h1">联系电话</div>
          <div class="phone">400-0550-766</div>
        </div>
      </div>
      <div class="view">
        <img src="../images/home/p10.png" alt="" />
        <span>官方微信</span>
        <div class="fw">
          <img src="../images/home/code.png" />
        </div>
      </div>
    </div>
    <div v-if="CeilingOn" @click="topFunction" class="Ceiling FadeIn">
      <i class="el-icon-upload2"></i>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      CeilingOn: false,
    };
  },
  watch: {},
  created() {
    window.addEventListener("scroll", this.scrollBroadside);
    this.topFunction();
  },
  methods: {
    scrollBroadside() {
      // dom滚动位置
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 900) {
        //返回顶部按钮是否显示
        this.CeilingOn = true;
      } else {
        this.CeilingOn = false;
      }
    },
    /**
     * 返回顶部
     */
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.popon {
  .ability {
    .view:hover {
      .fw {
        display: block;
      }
    }
    .view {
      .fw-phone{
        padding-left: 18px!important;
      }
      .fw {
        img {
          width: 100px;
          height: 100px;
        }
        .phone {
          font-size: 20px;
          color: #166dba;
          font-weight: bold;
          width: 150px;
          padding: 10px 0;
        }
        .h1 {
          font-size: 16px;
          color: #666666;
          padding-top: 10px;
        }
        background: white;
        padding: 4px;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
        position: absolute;
        right: 80px;
        top: 0;
        display: none;
      }
      .fw:before {
        content: "";
        position: absolute;
        left: 100%;
        top: 40%;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 8px solid white;
        border-bottom: 6px solid transparent;
      }
      img {
        width: 30px;
        height: 30px;
      }
      span {
        padding-top: 10px;
      }
      font-size: 14px;
      color: #666666;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      margin: 0 10px;
      position: relative;
    }
    .zhong {
      // border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
    }
    display: flex;
    flex-direction: column;
    width: 77px;
    background: #ffffff;
    border-radius: 36px;
    padding: 10px 0;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  }
  .Ceiling:hover {
    background: #166dba;
    color: white;
  }
  .Ceiling {
    color: #166dba;
    text-align: center;
    line-height: 70px;
    font-size: 35px;
    font-weight: bold;
    width: 72px;
    height: 72px;
    background: #ffffff;
    border-radius: 36px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    margin-top: 20px;
  }
  width: 72px;
  position: fixed;
  bottom: 10%;
  right: 40px;
  cursor: pointer;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
</style>
