import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/trends',
    name: 'trends',
    component: () => import('../views/trends/index.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/trends/details.vue')
  },
  {
    path: '/programme',
    name: 'programme',
    component: () => import('../views/programme/index.vue'),
    children: [{
      path: 'laborServices',
      name: 'laborServices',
      component: () => import('../views/programme/laborServices.vue'),
    },{
      path: 'talent',
      name: 'talent',
      component: () => import('../views/programme/talent.vue'),
    },{
      path: 'technology',
      name: 'technology',
      component: () => import('../views/programme/technology.vue'),
    }]
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/articleManager/login.vue')
  },
  {
    path: '/manager',
    name: 'manager',
    component: () => import('../views/articleManager/index.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router