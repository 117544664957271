<template>
  <div class="subHead">
    <div class="content">
      <a href="/">
        <div class="flex">
          <img class="logo" src="../images/home/LOGO.png" alt="海恒"/>
          <img class="logo" src="../images/home/logoName.png" alt="海恒科技集团" />
        </div>
      </a>
      <div class="list flex">
        <template v-for="(item, index) in Navigation">
          <el-popover
            placement="bottom"
            width="100"
            trigger="hover"
            :key="index"
            :disabled="!item.subpage"
          >
            <div
              v-for="(l, i) in item.subpage"
              :key="i"
              :class="{ subpageOn: subpageActive == i&&active == index }"
              @click="toPage(l.url)"
              class="popover"
            >
              {{ l.name }}
            </div>
            <div
              @click="toPage(item.url)"
              :class="{ on: active == index }"
              :key="index"
              slot="reference"
            >
              {{ item.name }}
            </div>
          </el-popover>
        </template>
      </div>
      <!-- <div class="Hotline flex">
				<img src="https://szj-asset.oss-cn-shenzhen.aliyuncs.com/official/icon/P2.png" >
				服务热线：400-162-5566
				<a href="https://hrms.shangzhijia.cn" target="_blank" class="SignIn">
					企业登录
				</a>
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "subHead",
  props: {
    active: Number,
    subpageActive: Number
  },
  data() {
    return {
      Navigation: [
        {
          name: "首页",
          url: "/",
          subpage: null,
        },
        {
          name: "解决方案",
          url: "/programme/laborServices?show=0",
          subpage: [
            {
              name: "灵活用工",
              url: "/programme/laborServices?show=0",
            },
            {
              name: "人才配置",
              url: "/programme/talent?show=1",
            },
            {
              name: "数字员工",
              url: "/programme/technology?show=2",
            },
          ],
        },
        {
          name: "企业案例",
          url: "/case?show=0",
          subpage: [
            {
              name: "岗位外包",
              url: "/case?show=0",
            },
            {
              name: "业务外包",
              url: "/case?show=1",
            },
            {
              name: "劳务派遣",
              url: "/case?show=2",
            },
            {
              name: "招聘流程外包",
              url: "/case?show=3",
            },
          ],
        },
        {
          name: "海恒动态",
          url: "/trends?show=0",
          subpage: [
            {
              name: "公司新闻",
              url: "/trends?show=0",
            },
            {
              name: "媒体报道",
              url: "/trends?show=1",
            },
            // {
            //   name: "行业资讯",
            //   url: "/trends?show=2",
            // },
          ],
        },
        {
          name: "关于海恒",
          url: "/about?show=0",
          subpage: [
            {
              name: "集团概况",
              url: "/about?show=0",
            },
            {
              name: "社会责任",
              url: "/about?show=1",
            },
            {
              name: "联系我们",
              url: "/about?show=2",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {
    toPage(url) {
      url ? this.$router.push(url) : "";
    },
  },
};
</script>

<style>
.el-popover {
  min-width: 100px;
  text-align: center;
  padding: 10px 0;
}
</style>
<style lang="less" scoped>
.popover {
  padding: 10px 0;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}
.popover:hover {
  color: #166dba;
  background: #dfeffc;
  font-weight: bold;
}
.subpageOn {
  color: #166dba;
  background: #dfeffc;
  font-weight: bold;
}
.subHead {
  .on {
    color: #05447c !important;
    font-weight: bold;
    position: relative;
  }
  .on:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 4px;
    background: #05447c;
  }
  .Hotline {
    .SignIn:hover {
      border: 1px solid #05447c;
      text-decoration: none;
    }
    .SignIn {
      width: 80px;
      height: 30px;
      border: 1px solid #999999;
      border-radius: 15px;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      margin-left: 25px;
    }
    img {
      width: 12px;
      height: 16px;
      margin-right: 9px;
      margin-top: 3px;
    }
    color: #666666;
    font-size: 16px;
    margin-left: 190px;
  }
  .list {
    div:hover {
      color: #05447c;
    }
    div {
      cursor: pointer;
    }
    width: 650px;
    margin-left: 300px;
    font-size: 16px;
    color: #333333;
  }
  .logo {
    // width: 193px;
    height: 39px;
  }
  .content {
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
  }
  background-color: #fff;
  padding: 25px 0;
  width: 100%;
}
</style>
